import React from 'react'

const PHP = props => {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="711.201"
      height="383.598"
      version="1.1"
      viewBox="0 0 711.201 383.598"
      xmlSpace="preserve"
    >
      <defs>
        <clipPath id="clipPath3444" clipPathUnits="userSpaceOnUse">
          <path d="M11.52 162C11.52 81.677 135.307 16.561 288 16.561S564.481 81.677 564.481 162c0 80.322-123.788 145.439-276.481 145.439-152.693 0-276.48-65.117-276.48-145.439"></path>
        </clipPath>
        <radialGradient
          id="radialGradient3452"
          cx="0"
          cy="0"
          r="1"
          fx="0"
          fy="0"
          gradientTransform="matrix(363.05789 0 0 -363.05789 177.52 256.307)"
          gradientUnits="userSpaceOnUse"
          spreadMethod="pad"
        >
          <stop offset="0" stopColor="#aeb2d5" stopOpacity="1"></stop>
          <stop offset="0.3" stopColor="#aeb2d5" stopOpacity="1"></stop>
          <stop offset="0.75" stopColor="#484c89" stopOpacity="1"></stop>
          <stop offset="1" stopColor="#484c89" stopOpacity="1"></stop>
        </radialGradient>
        <clipPath id="clipPath3468" clipPathUnits="userSpaceOnUse">
          <path d="M0 324h576V0H0v324z"></path>
        </clipPath>
        <clipPath id="clipPath3480" clipPathUnits="userSpaceOnUse">
          <path d="M0 324h576V0H0v324z"></path>
        </clipPath>
      </defs>
      <g transform="matrix(1.25 0 0 -1.25 -4.4 394.299)">
        <g>
          <g clipPath="url(#clipPath3444)">
            <g>
              <g>
                <path
                  fill="url(#radialGradient3452)"
                  stroke="none"
                  d="M11.52 162C11.52 81.677 135.307 16.561 288 16.561S564.481 81.677 564.481 162c0 80.322-123.788 145.439-276.481 145.439-152.693 0-276.48-65.117-276.48-145.439"
                ></path>
              </g>
            </g>
          </g>
        </g>
        <g>
          <g clipPath="url(#clipPath3468)">
            <g transform="translate(288 27.36)">
              <path
                fill="#777bb3"
                fillOpacity="1"
                fillRule="nonzero"
                stroke="none"
                d="M0 0c146.729 0 265.68 60.281 265.68 134.641C265.68 209 146.729 269.282 0 269.282S-265.68 209-265.68 134.641C-265.68 60.281-146.729 0 0 0"
              ></path>
            </g>
          </g>
        </g>
        <g>
          <g clipPath="url(#clipPath3480)">
            <g transform="translate(161.734 145.307)">
              <path
                fill="#000"
                fillOpacity="1"
                fillRule="nonzero"
                stroke="none"
                d="M0 0c12.065 0 21.072 2.225 26.771 6.611 5.638 4.341 9.532 11.862 11.573 22.353 1.903 9.806 1.178 16.653-2.154 20.348C32.783 53.086 25.417 55 14.297 55H-4.984L-15.673 0H0zm-63.063-67.75a2.998 2.998 0 00-2.944 3.572l28.328 145.751A3 3 0 00-34.734 84H26.32c19.188 0 33.47-5.21 42.447-15.487 9.025-10.331 11.812-24.772 8.283-42.921-1.436-7.394-3.906-14.261-7.341-20.409-3.439-6.155-7.984-11.85-13.511-16.93-6.616-6.192-14.104-10.682-22.236-13.324C25.959-27.678 15.681-29 3.414-29h-24.722l-7.06-36.322a3 3 0 00-2.944-2.428h-31.751z"
              ></path>
            </g>
            <g transform="translate(159.224 197.307)">
              <path
                fill="#fff"
                fillOpacity="1"
                fillRule="nonzero"
                stroke="none"
                d="M0 0h16.808c13.421 0 18.083-2.945 19.667-4.7 2.628-2.914 3.124-9.058 1.435-17.767-1.898-9.75-5.416-16.663-10.458-20.545C22.29-46.986 13.898-49 2.511-49H-9.523L0 0zm28.831 35h-61.055a6 6 0 01-5.889-4.855l-28.328-145.751a6 6 0 015.889-7.144h31.75a6 6 0 015.89 4.855L-16.324-84H5.925c12.582 0 23.174 1.372 31.479 4.077 8.541 2.775 16.399 7.48 23.354 13.984 5.752 5.292 10.49 11.232 14.08 17.657 3.591 6.427 6.171 13.594 7.668 21.302 3.715 19.104.697 34.402-8.969 45.466C63.965 29.444 48.923 35 28.831 35m-45.633-90H2.511c12.801 0 22.336 2.411 28.601 7.234 6.266 4.824 10.492 12.875 12.688 24.157C45.901-12.777 44.944-5.133 40.929-.68 36.909 3.773 28.87 6 16.808 6H-4.946l-11.856-61m45.633 84c18.367 0 31.766-4.82 40.188-14.461 8.421-9.641 10.957-23.098 7.597-40.375-1.383-7.117-3.722-13.624-7.015-19.519-3.297-5.899-7.602-11.293-12.922-16.184-6.34-5.933-13.383-10.161-21.133-12.679C27.796-76.743 17.925-78 5.925-78h-27.196l-7.531-38.75h-31.75L-32.224 29h61.055"
              ></path>
            </g>
            <g transform="translate(311.583 116.307)">
              <path
                fill="#000"
                fillOpacity="1"
                fillRule="nonzero"
                stroke="none"
                d="M0 0c-.896 0-1.745.4-2.314 1.092a2.994 2.994 0 00-.631 2.48L9.586 68.061c1.192 6.133.898 10.535-.827 12.395C7.703 81.593 4.531 83.5-4.848 83.5H-27.55L-43.305 2.428A3 3 0 00-46.25 0h-31.5a2.998 2.998 0 00-2.945 3.572l28.328 145.751a3 3 0 002.945 2.427h31.5a3 3 0 002.945-3.572L-21.813 113H2.609c18.605 0 31.221-3.28 38.569-10.028 7.49-6.884 9.827-17.891 6.947-32.719L34.945 2.428A3 3 0 0032 0H0z"
              ></path>
            </g>
            <g transform="translate(293.661 271.057)">
              <path
                fill="#fff"
                fillOpacity="1"
                fillRule="nonzero"
                stroke="none"
                d="M0 0h-31.5a6 6 0 01-5.89-4.855l-28.328-145.751a5.998 5.998 0 015.89-7.144h31.5a6 6 0 015.89 4.855L-7.155-74.25h20.229c9.363 0 11.328-2 11.407-2.086.568-.611 1.315-3.441.082-9.781l-12.531-64.489a5.998 5.998 0 015.89-7.144h32a6 6 0 015.89 4.855L68.991-85.07c3.093 15.921.447 27.864-7.861 35.5-7.928 7.281-21.208 10.82-40.599 10.82H-.253L5.89-7.145A6.001 6.001 0 010 0m0-6l-7.531-38.75h28.062c17.657 0 29.836-3.082 36.539-9.238 6.703-6.16 8.711-16.141 6.032-29.938l-13.18-67.824h-32l12.531 64.488c1.426 7.336.902 12.34-1.574 15.008-2.477 2.668-7.746 4.004-15.805 4.004h-25.176l-16.226-83.5h-31.5L-31.5-6H0"
              ></path>
            </g>
            <g transform="translate(409.55 145.307)">
              <path
                fill="#000"
                fillOpacity="1"
                fillRule="nonzero"
                stroke="none"
                d="M0 0c12.065 0 21.072 2.225 26.771 6.611 5.638 4.34 9.532 11.861 11.574 22.353 1.903 9.806 1.178 16.653-2.155 20.348C32.783 53.086 25.417 55 14.297 55H-4.984L-15.673 0H0zm-63.062-67.75a2.999 2.999 0 00-2.945 3.572l28.328 145.751A3.002 3.002 0 00-34.733 84H26.32c19.189 0 33.47-5.21 42.448-15.487 9.025-10.33 11.811-24.771 8.283-42.921-1.438-7.394-3.907-14.261-7.342-20.409-3.439-6.155-7.984-11.85-13.511-16.93-6.616-6.192-14.104-10.682-22.236-13.324C25.959-27.678 15.681-29 3.414-29h-24.723l-7.057-36.322a3.001 3.001 0 00-2.946-2.428h-31.75z"
              ></path>
            </g>
            <g transform="translate(407.04 197.307)">
              <path
                fill="#fff"
                fillOpacity="1"
                fillRule="nonzero"
                stroke="none"
                d="M0 0h16.808c13.421 0 18.083-2.945 19.667-4.7 2.629-2.914 3.125-9.058 1.435-17.766-1.898-9.751-5.417-16.664-10.458-20.546C22.29-46.986 13.898-49 2.511-49H-9.522L0 0zm28.831 35h-61.054a6 6 0 01-5.889-4.855L-66.44-115.606a6 6 0 015.889-7.144h31.75a6 6 0 015.89 4.855L-16.324-84H5.925c12.582 0 23.174 1.372 31.479 4.077 8.541 2.775 16.401 7.481 23.356 13.986 5.752 5.291 10.488 11.23 14.078 17.655 3.591 6.427 6.171 13.594 7.668 21.302 3.715 19.105.697 34.403-8.969 45.467C63.965 29.444 48.924 35 28.831 35m-45.632-90H2.511c12.801 0 22.336 2.411 28.601 7.234 6.267 4.824 10.492 12.875 12.688 24.157C45.902-12.777 44.945-5.133 40.929-.68 36.909 3.773 28.87 6 16.808 6H-4.946l-11.855-61m45.632 84c18.367 0 31.766-4.82 40.188-14.461 8.422-9.641 10.957-23.098 7.597-40.375-1.383-7.117-3.722-13.624-7.015-19.519-3.297-5.899-7.602-11.293-12.922-16.184-6.34-5.933-13.383-10.161-21.133-12.679C27.796-76.743 17.925-78 5.925-78h-27.196l-7.53-38.75h-31.75L-32.223 29h61.054"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}
export default PHP
