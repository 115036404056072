import React from 'react'

const Docker = props => {
  return (
    <svg
      {...props}
      viewBox="0 0 160 115"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M158.424 43.3965C157.991 43.0528 153.939 39.9926 145.393 39.9926C143.262 39.9926 140.997 40.1811 138.667 40.5692C137.013 29.2488 127.656 23.7273 127.223 23.4833L124.925 22.1639L123.416 24.3371C123.305 24.5034 120.641 28.3618 119.342 33.8612C117.81 40.3252 118.743 46.3901 122.028 51.5791C118.821 53.3641 114.07 54.0737 111.672 54.2955V39.6156C111.672 37.7086 110.13 36.1674 108.22 36.1674H91.9596V22.5852V21.5319V3.44821C91.9596 1.54116 90.4168 0 88.5076 0H72.7796C70.8705 0 69.3276 1.54116 69.3276 3.44821V18.0837H53.7549H53.2666H33.5094C31.6002 18.0837 30.0574 19.6249 30.0574 21.5319V36.1674H14.0297C12.1205 36.1674 10.5777 37.7086 10.5777 39.6156V54.3842H6.31547C4.61724 54.3842 3.0189 55.0494 1.82015 56.258C0.621398 57.4665 -0.022376 59.0631 -0.000176929 60.7595C0.121918 70.2282 1.80905 79.3311 4.87253 87.0702C8.50208 96.2395 13.8188 103.025 20.6783 107.216C28.5479 112.039 41.3789 114.8 55.8528 114.8C62.6235 114.8 69.3831 114.19 75.3769 113.037C84.8337 111.219 93.4136 108.103 100.884 103.79C107.399 100.02 113.26 95.2416 118.277 89.5648C126.635 80.1294 131.607 69.5963 135.303 60.2606C135.803 60.2827 136.291 60.2938 136.78 60.2938C145.926 60.2938 151.542 56.646 154.65 53.5859C158.013 50.2597 159.212 46.9556 159.345 46.5897L160 44.6715L158.424 43.3965Z"
        fill="#2854A1"
      />
      <path
        d="M28.6479 39.1832H15.1398C14.2851 39.1832 13.5969 39.8707 13.5969 40.7244V52.6767C13.5969 53.5305 14.2851 54.2179 15.1398 54.2179H28.6479C29.5026 54.2179 30.1908 53.5305 30.1908 52.6767V40.7244C30.1908 39.8707 29.5026 39.1832 28.6479 39.1832Z"
        fill="#2496ED"
      />
      <path
        d="M18.1256 50.8251C18.1256 51.0358 17.9591 51.2132 17.7371 51.2132H16.9935C16.7826 51.2132 16.605 51.0468 16.605 50.8251V42.5982C16.605 42.3875 16.7715 42.2101 16.9935 42.2101H17.7371C17.948 42.2101 18.1256 42.3875 18.1256 42.5982V50.8251Z"
        fill="#2854A1"
      />
      <path
        d="M22.6542 50.8251C22.6542 51.0358 22.4877 51.2132 22.2657 51.2132H21.522C21.3111 51.2132 21.1335 51.0468 21.1335 50.8251V42.5982C21.1335 42.3875 21.3 42.2101 21.522 42.2101H22.2657C22.4766 42.2101 22.6542 42.3875 22.6542 42.5982V50.8251Z"
        fill="#2854A1"
      />
      <path
        d="M27.1718 50.8251C27.1718 51.0358 27.0053 51.2132 26.7833 51.2132H26.0396C25.8287 51.2132 25.6511 51.0468 25.6511 50.8251V42.5982C25.6511 42.3875 25.8176 42.2101 26.0396 42.2101H26.7833C26.9942 42.2101 27.1718 42.3875 27.1718 42.5982V50.8251Z"
        fill="#2854A1"
      />
      <path
        d="M48.1054 39.1832H34.5973C33.7426 39.1832 33.0544 39.8707 33.0544 40.7244V52.6767C33.0544 53.5305 33.7426 54.2179 34.5973 54.2179H48.1054C48.9601 54.2179 49.6483 53.5305 49.6483 52.6767V40.7244C49.6483 39.8707 48.9601 39.1832 48.1054 39.1832Z"
        fill="#2496ED"
      />
      <path
        d="M37.5831 50.8251C37.5831 51.0358 37.4166 51.2132 37.1947 51.2132H36.451C36.2401 51.2132 36.0625 51.0468 36.0625 50.8251V42.5982C36.0625 42.3875 36.229 42.2101 36.451 42.2101H37.1947C37.4055 42.2101 37.5831 42.3875 37.5831 42.5982V50.8251Z"
        fill="#2854A1"
      />
      <path
        d="M42.1117 50.8251C42.1117 51.0358 41.9452 51.2132 41.7232 51.2132H40.9795C40.7687 51.2132 40.5911 51.0468 40.5911 50.8251V42.5982C40.5911 42.3875 40.7576 42.2101 40.9795 42.2101H41.7232C41.9341 42.2101 42.1117 42.3875 42.1117 42.5982V50.8251Z"
        fill="#2854A1"
      />
      <path
        d="M46.6403 50.8251C46.6403 51.0358 46.4738 51.2132 46.2518 51.2132H45.5081C45.2972 51.2132 45.1196 51.0468 45.1196 50.8251V42.5982C45.1196 42.3875 45.2861 42.2101 45.5081 42.2101H46.2518C46.4627 42.2101 46.6403 42.3875 46.6403 42.5982V50.8251Z"
        fill="#2854A1"
      />
      <path
        d="M67.8738 39.1832H54.3656C53.5109 39.1832 52.8228 39.8707 52.8228 40.7244V52.6767C52.8228 53.5305 53.5109 54.2179 54.3656 54.2179H67.8738C68.7284 54.2179 69.4166 53.5305 69.4166 52.6767V40.7244C69.4166 39.8707 68.7284 39.1832 67.8738 39.1832Z"
        fill="#2496ED"
      />
      <path
        d="M57.3515 50.8251C57.3515 51.0358 57.185 51.2132 56.963 51.2132H56.2193C56.0084 51.2132 55.8308 51.0468 55.8308 50.8251V42.5982C55.8308 42.3875 55.9973 42.2101 56.2193 42.2101H56.963C57.1739 42.2101 57.3515 42.3875 57.3515 42.5982V50.8251Z"
        fill="#2854A1"
      />
      <path
        d="M61.869 50.8251C61.869 51.0358 61.7025 51.2132 61.4805 51.2132H60.7369C60.526 51.2132 60.3484 51.0468 60.3484 50.8251V42.5982C60.3484 42.3875 60.5149 42.2101 60.7369 42.2101H61.4805C61.6914 42.2101 61.869 42.3875 61.869 42.5982V50.8251Z"
        fill="#2854A1"
      />
      <path
        d="M66.3973 50.8251C66.3973 51.0358 66.2309 51.2132 66.0089 51.2132H65.2652C65.0543 51.2132 64.8767 51.0468 64.8767 50.8251V42.5982C64.8767 42.3875 65.0432 42.2101 65.2652 42.2101H66.0089C66.2198 42.2101 66.3973 42.3875 66.3973 42.5982V50.8251Z"
        fill="#2854A1"
      />
      <path
        d="M87.3979 39.1832H73.8898C73.0351 39.1832 72.3469 39.8707 72.3469 40.7244V52.6767C72.3469 53.5305 73.0351 54.2179 73.8898 54.2179H87.3979C88.2526 54.2179 88.9408 53.5305 88.9408 52.6767V40.7244C88.9408 39.8707 88.2526 39.1832 87.3979 39.1832Z"
        fill="#2496ED"
      />
      <path
        d="M76.8756 50.8251C76.8756 51.0358 76.7091 51.2132 76.4871 51.2132H75.7435C75.5326 51.2132 75.355 51.0468 75.355 50.8251V42.5982C75.355 42.3875 75.5215 42.2101 75.7435 42.2101H76.4871C76.698 42.2101 76.8756 42.3875 76.8756 42.5982V50.8251Z"
        fill="#2854A1"
      />
      <path
        d="M81.393 50.8251C81.393 51.0358 81.2265 51.2132 81.0045 51.2132H80.2608C80.0499 51.2132 79.8723 51.0468 79.8723 50.8251V42.5982C79.8723 42.3875 80.0388 42.2101 80.2608 42.2101H81.0045C81.2154 42.2101 81.393 42.3875 81.393 42.5982V50.8251Z"
        fill="#2854A1"
      />
      <path
        d="M85.9216 50.8251C85.9216 51.0358 85.7551 51.2132 85.5332 51.2132H84.8006C84.5897 51.2132 84.4121 51.0468 84.4121 50.8251V42.5982C84.4121 42.3875 84.5786 42.2101 84.8006 42.2101H85.5443C85.7551 42.2101 85.9327 42.3875 85.9327 42.5982V50.8251H85.9216Z"
        fill="#2854A1"
      />
      <path
        d="M48.1054 21.0995H34.5973C33.7426 21.0995 33.0544 21.787 33.0544 22.6407V34.593C33.0544 35.4468 33.7426 36.1342 34.5973 36.1342H48.1054C48.9601 36.1342 49.6483 35.4468 49.6483 34.593V22.6407C49.6483 21.787 48.9601 21.0995 48.1054 21.0995Z"
        fill="#2496ED"
      />
      <path
        d="M37.5831 32.7414C37.5831 32.9521 37.4166 33.1295 37.1947 33.1295H36.451C36.2401 33.1295 36.0625 32.9631 36.0625 32.7414V24.5145C36.0625 24.3038 36.229 24.1264 36.451 24.1264H37.1947C37.4055 24.1264 37.5831 24.2927 37.5831 24.5145V32.7414Z"
        fill="#2854A1"
      />
      <path
        d="M42.1117 32.7414C42.1117 32.9521 41.9452 33.1295 41.7232 33.1295H40.9795C40.7687 33.1295 40.5911 32.9631 40.5911 32.7414V24.5145C40.5911 24.3038 40.7576 24.1264 40.9795 24.1264H41.7232C41.9341 24.1264 42.1117 24.2927 42.1117 24.5145V32.7414Z"
        fill="#2854A1"
      />
      <path
        d="M46.6403 32.7414C46.6403 32.9521 46.4738 33.1295 46.2518 33.1295H45.5081C45.2972 33.1295 45.1196 32.9631 45.1196 32.7414V24.5145C45.1196 24.3038 45.2861 24.1264 45.5081 24.1264H46.2518C46.4627 24.1264 46.6403 24.2927 46.6403 24.5145V32.7414Z"
        fill="#2854A1"
      />
      <path
        d="M67.8738 21.0995H54.3656C53.5109 21.0995 52.8228 21.787 52.8228 22.6407V34.593C52.8228 35.4468 53.5109 36.1342 54.3656 36.1342H67.8738C68.7284 36.1342 69.4166 35.4468 69.4166 34.593V22.6407C69.4166 21.787 68.7284 21.0995 67.8738 21.0995Z"
        fill="#2496ED"
      />
      <path
        d="M57.3515 32.7414C57.3515 32.9521 57.185 33.1295 56.963 33.1295H56.2193C56.0084 33.1295 55.8308 32.9631 55.8308 32.7414V24.5145C55.8308 24.3038 55.9973 24.1264 56.2193 24.1264H56.963C57.1739 24.1264 57.3515 24.2927 57.3515 24.5145V32.7414Z"
        fill="#2854A1"
      />
      <path
        d="M61.869 32.7414C61.869 32.9521 61.7025 33.1295 61.4805 33.1295H60.7369C60.526 33.1295 60.3484 32.9631 60.3484 32.7414V24.5145C60.3484 24.3038 60.5149 24.1264 60.7369 24.1264H61.4805C61.6914 24.1264 61.869 24.2927 61.869 24.5145V32.7414Z"
        fill="#2854A1"
      />
      <path
        d="M66.3973 32.7414C66.3973 32.9521 66.2309 33.1295 66.0089 33.1295H65.2652C65.0543 33.1295 64.8767 32.9631 64.8767 32.7414V24.5145C64.8767 24.3038 65.0432 24.1264 65.2652 24.1264H66.0089C66.2198 24.1264 66.3973 24.2927 66.3973 24.5145V32.7414Z"
        fill="#2854A1"
      />
      <path
        d="M87.3979 21.0995H73.8898C73.0351 21.0995 72.3469 21.787 72.3469 22.6407V34.593C72.3469 35.4468 73.0351 36.1342 73.8898 36.1342H87.3979C88.2526 36.1342 88.9408 35.4468 88.9408 34.593V22.6407C88.9408 21.787 88.2526 21.0995 87.3979 21.0995Z"
        fill="#2496ED"
      />
      <path
        d="M76.8756 32.7414C76.8756 32.9521 76.7091 33.1295 76.4871 33.1295H75.7435C75.5326 33.1295 75.355 32.9631 75.355 32.7414V24.5145C75.355 24.3038 75.5215 24.1264 75.7435 24.1264H76.4871C76.698 24.1264 76.8756 24.2927 76.8756 24.5145V32.7414Z"
        fill="#2854A1"
      />
      <path
        d="M81.393 32.7414C81.393 32.9521 81.2265 33.1295 81.0045 33.1295H80.2608C80.0499 33.1295 79.8723 32.9631 79.8723 32.7414V24.5145C79.8723 24.3038 80.0388 24.1264 80.2608 24.1264H81.0045C81.2154 24.1264 81.393 24.2927 81.393 24.5145V32.7414Z"
        fill="#2854A1"
      />
      <path
        d="M85.9216 32.7414C85.9216 32.9521 85.7551 33.1295 85.5332 33.1295H84.8006C84.5897 33.1295 84.4121 32.9631 84.4121 32.7414V24.5145C84.4121 24.3038 84.5786 24.1264 84.8006 24.1264H85.5443C85.7551 24.1264 85.9327 24.2927 85.9327 24.5145V32.7414H85.9216Z"
        fill="#2854A1"
      />
      <path
        d="M87.3979 3.01581H73.8898C73.0351 3.01581 72.3469 3.70323 72.3469 4.55697V16.5093C72.3469 17.363 73.0351 18.0505 73.8898 18.0505H87.3979C88.2526 18.0505 88.9408 17.363 88.9408 16.5093V4.55697C88.9408 3.70323 88.2526 3.01581 87.3979 3.01581Z"
        fill="#2496ED"
      />
      <path
        d="M76.8756 14.6577C76.8756 14.8683 76.7091 15.0457 76.4871 15.0457H75.7435C75.5326 15.0457 75.355 14.8794 75.355 14.6577V6.43076C75.355 6.22009 75.5215 6.04269 75.7435 6.04269H76.4871C76.698 6.04269 76.8756 6.20901 76.8756 6.43076V14.6577Z"
        fill="#2854A1"
      />
      <path
        d="M81.393 14.6577C81.393 14.8683 81.2265 15.0457 81.0045 15.0457H80.2608C80.0499 15.0457 79.8723 14.8794 79.8723 14.6577V6.43076C79.8723 6.22009 80.0388 6.04269 80.2608 6.04269H81.0045C81.2154 6.04269 81.393 6.20901 81.393 6.43076V14.6577Z"
        fill="#2854A1"
      />
      <path
        d="M85.9216 14.6577C85.9216 14.8683 85.7551 15.0457 85.5332 15.0457H84.8006C84.5897 15.0457 84.4121 14.8794 84.4121 14.6577V6.43076C84.4121 6.22009 84.5786 6.04269 84.8006 6.04269H85.5443C85.7551 6.04269 85.9327 6.20901 85.9327 6.43076V14.6577H85.9216Z"
        fill="#2854A1"
      />
      <path
        d="M107.1 39.1832H93.5914C92.7368 39.1832 92.0486 39.8707 92.0486 40.7244V52.6767C92.0486 53.5305 92.7368 54.2179 93.5914 54.2179H107.1C107.954 54.2179 108.642 53.5305 108.642 52.6767V40.7244C108.642 39.8707 107.943 39.1832 107.1 39.1832Z"
        fill="#2496ED"
      />
      <path
        d="M96.5663 50.8251C96.5663 51.0358 96.3998 51.2132 96.1778 51.2132H95.4341C95.2233 51.2132 95.0457 51.0468 95.0457 50.8251V42.5982C95.0457 42.3875 95.2122 42.2101 95.4341 42.2101H96.1778C96.3887 42.2101 96.5663 42.3875 96.5663 42.5982V50.8251Z"
        fill="#2854A1"
      />
      <path
        d="M101.095 50.8251C101.095 51.0358 100.928 51.2132 100.706 51.2132H99.9625C99.7516 51.2132 99.574 51.0468 99.574 50.8251V42.5982C99.574 42.3875 99.7405 42.2101 99.9625 42.2101H100.706C100.917 42.2101 101.095 42.3875 101.095 42.5982V50.8251Z"
        fill="#2854A1"
      />
      <path
        d="M105.623 50.8251C105.623 51.0358 105.457 51.2132 105.235 51.2132H104.491C104.28 51.2132 104.103 51.0468 104.103 50.8251V42.5982C104.103 42.3875 104.269 42.2101 104.491 42.2101H105.235C105.446 42.2101 105.623 42.3875 105.623 42.5982V50.8251Z"
        fill="#2854A1"
      />
      <path
        d="M2.88581 60.4934C2.87471 58.7083 4.31765 57.2558 6.09358 57.2558H110.396C111.151 57.2558 121.529 56.7126 126.002 52.1778C115.99 40.037 125.769 25.978 125.769 25.978C125.769 25.978 136.014 31.8765 136.014 44.0285C150.443 40.6911 156.603 45.6361 156.603 45.6361C156.603 45.6361 152.008 59.052 133.383 57.2337C126.446 75.2065 113.781 102.704 74.822 110.21C57.984 113.447 34.5528 112.339 22.1768 104.766C8.63537 96.4723 3.0301 77.5792 2.88581 60.4934Z"
        fill="#2496ED"
      />
      <path
        d="M51.3133 89.0659C54.2129 89.0659 56.5634 86.703 56.5634 83.7883C56.5634 80.8735 54.2129 78.5106 51.3133 78.5106C48.4138 78.5106 46.0632 80.8735 46.0632 83.7883C46.0632 86.703 48.4138 89.0659 51.3133 89.0659Z"
        fill="white"
      />
      <path
        d="M51.7572 82.1695C51.6462 81.6927 51.7905 81.2492 52.068 80.8944C51.624 80.7725 51.1467 80.7503 50.6583 80.8612C49.0489 81.227 48.0388 82.8347 48.4051 84.4646C48.7714 86.0834 50.3808 87.1034 52.0014 86.7375C53.6108 86.3717 54.6209 84.764 54.2546 83.1341C54.2435 83.0787 54.2102 83.0232 54.1991 82.9678C54.0215 83.123 53.8106 83.2561 53.5664 83.3115C52.7562 83.5 51.9459 82.99 51.7572 82.1695Z"
        fill="#2854A1"
      />
      <path
        d="M42.0118 94.909C42.0007 94.909 42.0007 94.9201 41.9896 94.9201C35.5186 98.7564 28.093 100.785 20.5342 100.785C19.5463 100.785 18.1922 100.708 16.8491 100.586C18.4697 102.171 20.2345 103.568 22.1769 104.755C30.3018 109.722 43.1884 111.917 55.8418 111.917C58.4502 111.917 61.0475 111.829 63.5893 111.64C54.6098 108.713 47.0066 102.726 42.0118 94.909Z"
        fill="white"
      />
      <path
        d="M20.5341 100.785C18.4362 100.785 16.0831 100.586 14.2295 100.331C13.4414 100.22 12.5091 97.1931 13.3082 97.315C15.0287 97.5589 18.5916 97.9137 20.5341 97.9137C27.5712 97.9137 34.4862 96.0289 40.5132 92.4476C41.2014 92.0374 42.0783 92.2702 42.489 92.9466C42.8996 93.6229 42.6666 94.5099 41.9895 94.9201C35.5184 98.7564 28.0928 100.785 20.5341 100.785Z"
        fill="#2854A1"
      />
    </svg>
  )
}
export default Docker
